import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import H1 from "../components/H1"
import H2 from "../components/H2"
import P from "../components/P"
import Container from "../components/Container"
import Section from "../components/Section"
import contact from "../images/contact.svg"
import logo_kitatraumfaenger from "../images/logo_kitatraumfaenger.svg"
import logo_farbvision from "../images/logo_farbvision.svg"
import logo_eliza from "../images/logo_eliza.svg"
import logo_thaifood from "../images/logo_thaifood.svg"
import projects_hero from "../images/projects_hero.svg"
import Grid from "../components/Grid"
import ImageBlock from "../components/ImageBlock"
import Hero2 from "../components/Hero2"
import LinkButton from "../components/LinkButton"
import { Link } from "gatsby"

const dataClients = [
  {
    company: "ELIZA",
    companyDescription:
      "Die einfach zu bedienende Qualitätsmanagement Software aus der Schweizer Cloud.",
    description:
      "codeflow untsertütze ELIZA bei der Entwicklung Ihres Web-Auftritts. Wir sind sehr stolz auf dieses Projekt und wünschen viel Erfolg!",
    logoSrc: logo_eliza,
    link: "https://eliza.swiss",
    linkText: "Webseite ansehen",
  },
  {
    company: "farbvision - Sari Bazar",
    companyDescription:
      "Wenn Sie das Besondere aus Indien mögen, dann sind Sie hier richtig.",
    description:
      "Für farbvision durften wir bereits in vergangenen Jahren einen Webshop aufsetzten. Am 11. August folgt ein Relaunch Webshop mithilfe neuer Technologie. Für den Relaunch durften wir ausserdem das Logo neu entwerfen..",
    logoSrc: logo_farbvision,
    link: "https://farbvision.ch",
    linkText: "Webseite ansehen",
  },
  {
    company: "Kita Traumfänger",
    companyDescription:
      "Meine Kita, hier führe ich mich zu Hause! Die Kinderfreundliche Kita eröffnet im August seine Tore.",
    description:
      "Für die Kita Traumfänger in Luzern haben wir das kompette Web-Konzept entwickelt. Von der Strategie, bis zum digtalen Design und schliesslich der Entwicklung waren wir tatkräftig am Werk.",
    logoSrc: logo_kitatraumfaenger,
    link: "https://kita-traumfaenger.ch",
    linkText: "Webseite ansehen",
  },
  {
    company: "Doremi Thai Food",
    companyDescription: "",
    description:
      "Für Doremi Thai Food in Luzern haben wir eine Menükarte konzipiert. Von der Strategie zur Fotografie bishin zum digitalen Druck waren wir tatkräftig am Werk.",
    logoSrc: logo_thaifood,
    link: "",
    linkText: "",
  },
  {
    company: "arsol Dimension GmbH",
    companyDescription: "",
    description:
      "Für arsol Dimension GmbH in Schwyz durften wir ein Webdesign für ein Dashboard eines Versicherungsunternehmens entwerfen.",
    logoSrc: "",
    link: "",
    linkText: "",
  },
]

const meta = {
  title: "Referenzen",
  description: `Erfolgreiche Projekte mit codeflow. Diese Kunden durften wir offline oder ins Web begleiten. Wir danken unseren Kunden für die tollen Projekte.`,
  keywords: "webagentur, projekte, referenzen, kunden",
}
const ReferenzenPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={meta.title}
        description={meta.description}
        keywords={meta.keywords}
      />

      <Hero2
        height={"100vh"}
        marginBottom={"120px"}
        marginBottomSm={"240px"}
        marginBottomMd={"240px"}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <H1>Referenzen</H1>
              <P>
                Mit viel Leidenschaft unterstützen wir unsere Kunden. Wir sagen
                danke für die tollen Projekte.
              </P>
              <LinkButton to="/referenzen#references">
                Referenzen ansehen
              </LinkButton>
            </Grid>
          </Grid>
        </Container>
        <ImageBlock
          src={projects_hero}
          borderRadius={"50%"}
          width={"100%"}
          widthSm={"100%"}
          widthMd={"50%"}
          widthLg={"50%"}
          position={{
            position: "absolute",
            bottom: "0",
            zIndex: "-1",
            left: "20%",
          }}
          positionSm={{
            position: "absolute",
            bottom: "0",
            zIndex: "-1",
            left: "20%",
          }}
          positionMd={{
            position: "absolute",
            bottom: "0",
            zIndex: "-1",
            left: "60%",
          }}
          positionLg={{
            position: "absolute",
            bottom: "0",
            zIndex: "-1",
            left: "60%",
          }}
        />
      </Hero2>

      <Section id="references">
        <Container>
          <H2 margin={"0 0 60px 0"} marginLg={"0 0 60px 0"}>
            Unsere Referenzen
          </H2>

          {dataClients.map(project => (
            <Section>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <ImageBlock
                    width={"50%"}
                    widthSm={"50%"}
                    widthMd={"50%"}
                    widthLg={"300px"}
                    src={project.logoSrc && project.logoSrc}
                    margin={"0 0 40px 0"}
                    marginSm={"0 0 40px 0"}
                    marginMd={"0 0 40px 0"}
                    marginLg={"0 0 40px 0"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <H2>{project.company}</H2>
                  <P>{project.companyDescription}</P>
                  <P>{project.description}</P>
                  <Link to={project.link}>
                    {project.linkText ? project.linkText : project.link}
                  </Link>
                </Grid>
              </Grid>
            </Section>
          ))}
        </Container>
      </Section>

      <Section
        paddingSm={"120px 0"}
        paddingLg={"120px 0"}
        marginSm={"0"}
        marginLg={"0"}
      >
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <H2>Bereit durchzustarten?</H2>

              <P>
                Sie haben Fragen oder sind bereit loszulegen? Perfekt!
                Kontaktieren Sie uns!
              </P>

              <P>
                Ihre Vision ist unsere Mission! Wir sind da um Ihr Unternehmen
                bestmöglich im Internet zu präsentieren.
              </P>
              <LinkButton to="/kontakt">
                Kostenloses Erstgespräch vereinbaren!
              </LinkButton>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default ReferenzenPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "menuekarte_thaifood.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 700, quality: 40) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
